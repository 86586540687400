<template>
    <div :class="'ol-popup ol-popup--general pop--box--'+id " @click="handleBackdropClick">
        <div class="ol-popup-wrapper" :style="maxWidth ? `max-width: ${maxWidth}` : ''">
            <div class="ol-popup-box ol-popup-box--address">
                <h4 class="ol-popup-box__title">{{title}}</h4>
                <a class="ol-popup-box__close" @click="hide(id)">
                    <i class="ol-fa fas fa-times"></i>
                </a>
                <div class="ol-popup-box__form add-fix">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Popup",
    props:[
        "title",
        "id",
        "max-width",
    ],
    methods:{
        show(ID_AS_PARAM=null) {
            let popup_class = ID_AS_PARAM ? "pop--box--"+ID_AS_PARAM:'ol-popup--general';
            document.getElementsByClassName(popup_class)[0].classList.add("show"), document.querySelector("body").classList.add("popup");
		},
        hide(ID_AS_PARAM=null){
            let popup_class = ID_AS_PARAM ? "pop--box--"+ID_AS_PARAM:'ol-popup--general';
            if(ID_AS_PARAM == 'youtubeCatalog'){
                let link = $("#playYoutube").attr("src");
                $("#playYoutube").attr("src", "");
                $("#playYoutube").attr("src", link);
            }
            document.getElementsByClassName(popup_class)[0].classList.remove("show"), document.querySelector("body").classList.remove("popup");
        },
        handleBackdropClick(e) {
            if(typeof e.target.className.includes != 'function') return;
            if(e?.target?.className?.includes('ol-popup--general')) this.hide(this.id);
        }
    },
}
</script>

<style>
.pop--box--youtubeCatalog .ol-popup-wrapper{
max-width: 30%;
}
.pop--box--popupVoucher .ol-popup-wrapper{
max-width: 20%;
}
@media (max-width: 768px) {
    .pop--box--youtubeCatalog .ol-popup-wrapper{
        max-width: 80%;
    }
    .pop--box--popupVoucher .ol-popup-wrapper{
        max-width: 80%;
    }
}
</style>