import http from '@/lib/axios';
import axios from 'axios'
const apiurl = {
    // Auth
    WS_AUTH: {
        async LOGIN(data) { return await http.post('auth/login', data) },
        async LOGOUT(){ return await http.post('auth/logout') },
        async REGISTER(data){ return await http.post('auth/register',data) },
        async SHOW() { return await http.post('more/user/show') },
        async MOREVERSARY(){return await http.get('more/user/moreversary')},
        async UPDATE_USER(data){return await http.put('more/user/update',data)},
        async SAVE_PHOTO(data){return await http.post('more/user/store',data)},
        async UPDATE_MAIL(data){return await http.put('more/user/email',data)},
		async RESET_PASSWORD(data){return await http.post('more/user/reset',data)},
        async HAVE_PWD(data){return await http.post('/more/user/have/password',data)},
        async PROGRESS_PROFILE(){return await http.get('more/user/check')},
        async LOGIN_FB(data){return await http.post('auth/facebook',data)},
        async LOGIN_GOOGLE(data){return await http.post('auth/google',data)},
        async SAVE_TOKEN(data){return await http.post('more/user/token',data)},
        async SAVE_LOCATION(data){return await http.post('more/user/set-location',data)},
    },
    // Mail
    WS_MAIL: {
        async REQUEST_SET_PWD(data){return await http.post('mail/reset/password',data)},
        async VALID_MAIL_PWD(tokenResetPwd){return await http.get('mail/reset/valid/'+tokenResetPwd)},
    },
    //Uni
    WS_UNI: {
        _PROVINCES:'uni/province',
        _CITIES:'uni/city',
        _CITY_ALL:'uni/city/all-city',
        _DISTRICTS: 'uni/district',
        _VILLAGES: 'uni/village',
        async PROVINCES(){ return await http.get(WS_UNI._PROVINCES) },
        async CITIES(){ return await http.get(WS_UNI._CITIES) },
        async CITY_ALL(){ return await http.get(WS_UNI._CITY_ALL) },
        async DISTRICTS(){ return await http.get(WS_UNI._DISTRICTS) },
        async VILLAGES() { return await http.get(WS_UNI._VILLAGES) },
    },
    //Profile
    WS_PROFILE: {
        ORDER: {
            async GET(data){ return await http.get('more/transaction/order?'+$.param(data)) },
            async GET_DETAIL(orderId){ return await http.get('more/transaction/order/'+orderId)},
            async CANCEL(ordersn,note){ return await http.post('more/transaction/order/cancel/'+ordersn,{"note_cancel":note})},
            async COMPLETE(ordersn){ return await http.post('more/transaction/order/complete/'+ordersn)},
        },
        EVENT: {
            async EVENT_LIST(){ return await http.post('more/event/index') },
            async MY_CLAIMS(data){ return await http.post('more/user/giveaway/index?'+$.param(data)) },
            async STORE(data){ return await http.post('more/user/giveaway/store',data) },
            async DELETE(id){ return await http.delete('more/user/giveaway/destroy/'+id) },
            async TRACKING(data){ return await http.post('more/transaction/order/tracking',data) },
        },
        PASSWORD: {
            async UPDATE(data) { return await http.post('/more/user/password',data) }
        },
        ADDRESS: {
            async GET(){ return await http.post('more/user/address/index') },
            async STORE(data){ return await http.post('more/user/address/store',data) },
            async SHOW(data){ return await http.post('more/user/address/show',data) },
            async UPDATE(data){ return await http.put('more/user/address/update',data) },
            async DELETE(data){ return await http.delete('more/user/address/destroy',{ data: data}) },
        },
        DROPSHIPPER: {
            async GET(){ return await http.post('more/user/dropshippers/index') },
            async STORE(data){ return await http.post('more/user/dropshippers/store',data) },
            async SHOW(data){ return await http.post('more/user/dropshippers/show',data) },
            async UPDATE(data){ return await http.put('more/user/dropshippers/update',data) },
            async DELETE(data){ return await http.delete('more/user/dropshippers/destroy',{ data: data}) },
        },
        BANK_ACCOUNT: {
            async BANKS(){ return await http.get('uni/bank') },
            async GET(){ return await http.post('more/user/banks/index') },
            async SHOW(data){ return await http.post('more/user/banks/show',data) },
            async STORE(data){ return await http.post('more/user/banks/store',data) },
            async UPDATE(data){ return await http.put('more/user/banks/update',data) },
            async DELETE(data){ return await http.delete('more/user/banks/destroy',{ data: data}) },
        },
        PRODUCT: {
            async SYNC_HISTORY(data) { return await http.post('more/user/sync/product', data) },
            async LAST_SEEN() { return await http.get('uni/product?per_page=8&type=lastseen') },
            async LIST_PAKET() { return await http.get('uni/product?per_page=8&category_group=123&type=terbaru') },
            async DO_REVIEW(data) { return await http.post('more/product/review', data) },
            async REVIEW_TEMPLATES() { return await http.get('more/product/review/templates') },
            async REVIEW_IMAGE_DELETE(id) { return await http.post('more/product/review/delete-file',{"id":id}) },
            _REVIEW_IMAGE_UPLOAD: '/more/product/review/upload',
            async DO_COMPLAIN(data) { return await http.post('more/product/complain', data) },
            async COMPLAIN_TEMPLATES() { return await http.get('more/product/complain/templates') },
            async COMPLAIN_IMAGE_DELETE(id) { return await http.post('more/product/complain/delete-file',{"id":id}) },
            _COMPLAIN_IMAGE_UPLOAD: '/more/product/complain/upload',
            async COMPLAIN_IMAGE_MULTI_UPLOAD(data) { return await http.post('more/product/complain/multi-upload',data) },
        }
    },
    WS_OTP: {
        async SEND_MAIL_OTP(data){ return await http.post('mail/otp',data) },
        async VALID_MAIL_OTP(otp) { return await http.post('mail/otp/' + otp) },
        async SEND_WA_OTP(data){ return await http.post('message/wa/otp',data) },
        async VALID_WA_OTP(otp,data){ return await http.post('message/wa/otp/'+otp,data) },
    },
    WS_PRODUCT: {
        async GET(data){ return await http.get('uni/product?'+$.param(data)) },
        async SHOW(slug) { return await http.get('uni/product/' + slug) },
        async BUNDLE(slug){ return await http.get('uni/product/bundle/'+slug) },
        async TOP(data){ return await http.get('uni/product/top?'+$.param(data)) },
        async GET_KATEGORI(){return await http.get('uni/category')},
        async GET_KATEGORI_GROUP(){return await http.get('uni/category/group')},
        async GET_FILTER_DATA() { return await http.get('uni/product/filter') },
        async LAXY() { return await http.get('uni/product?q=laxy') },
        async RELATED(categories_id) { return await http.get('uni/product?per_page=8&' + $.param({ 'categories':categories_id })) },
        async OTHERS_BUNDLE(not_id) { return await http.get('uni/product?per_page=5&' + $.param({ 'is_bundle':true,'not_id':not_id,'type':'lainnya' })) },
        async PARTNER(product_id) { return await http.get('uni/product/partner/' + product_id) },
        async VOUCHER(product_id) { return await http.get('uni/product/voucher/' + product_id) },
        async RECOMMENDED() { return await http.get('uni/product?type=rekomendasi&per_page=5') },
        async RELATED_INSPIRATION(product_id) { return await http.get('uni/product/inspiration/'+product_id) },
        async PRODUCT_FLASH(data){ return await http.post('uni/product/flashsale',data) },
        async PRODUCT_FREE(data){ return await http.post('uni/product/free',data) },
        async PRODUCT_PO(data){ return await http.post('uni/product/po',data) },
        async REVIEWS(data) { return await http.get('more/product/review/'+data.product_id+'?'+$.param(data)) },
        async GROUPS() { return await http.get('uni/product/groups') },
        async GROUP(slug) { return await http.get('uni/product/group/'+slug) },
    },
    //Home
    WS_HOME: {
        async GET_BANNER(data){
            return await http.get('uni/banner?'+$.param(data)); 
        },
        async GET_PRODUCT_GROUP(){
            return await http.get('uni/product?type=koleksi-terbaik');
        },
        async GET_POP_UP(){
            return await http.get('uni/popup?application=MORE');
        },
        async GET_SETTING(){
            return await http.get('more/setting');
        },
        async GET_INSPIRASI(){
            return await http.get('uni/inspiration/highlight');
        },
        async GET_FLASHSALE(){
            return await http.get('more/promotion/flashsale');
        },
        async GET_FLASHSALE_GROUP(){
            return await http.get('more/promotion/flashsale-group')
        },
        async GET_ENDORSEMENT(){
            return await http.get('more/promotion/endorsement');
        },
        async GET_REEDEM(){
            return await http.get('more/promotion/reedem');
        },
        async GET_METAS(){
            return await http.get('uni/page');
        },
        async GET_FREE(){
            return await http.get('more/promotion/free')
        },
        async GET_PAKET(){
            return await http.get('more/promotion/paket')
        },
        async GET_PRODUCT_COMBO(){
            return await http.get('uni/product/combo')
        },
        async GET_IP() {
            return await axios.get('https://api.ipify.org/?format=json'); 
        }
    },
    WS_TRANSACTION: {
        CART: {
            async GET(){ return await http.get('more/transaction/cart'); },
            async SAVE(data){ return await http.post('more/transaction/cart',data); },
            async CHECK_STOCK(data){ return await http.post('more/transaction/cart/check-stock',data); },
            async MULTI_CHECK_STOCK(data){ return await http.post('more/transaction/cart/multiple-check-stock',data); },
            async GET_VOUCHER(){ return await http.get('/more/voucher?device=web');},
            async USE_VOUCHER(data){ return await http.post('more/transaction/cart/set-voucher',data); },
            async CART_FB_PIXEL(data){ return await http.post('more/transaction/cart/pixel',data); },
            async CHECK_LOCATION(data){ return await http.post('more/transaction/cart/check-location',data); },
            async CHECK_PRODUCT(data){ return await http.post('more/transaction/cart/check-product',data); },
            async CHECK_PRODUCT_ALL(data){ return await http.post('more/transaction/cart/check-product-all',data); },
        },
        CHECKOUT: {
            async PAYMENTS() { return await http.get('uni/payment'); },
            async PAYMENT_LIST() { return await http.get('uni/payment/list'); },
            async PRE_CHECKOUT_SUMMARY(data){ return await http.post('more/transaction/order/pre-checkout-summary',data); },
            async SAVE_TRANSACTION(data){ return await http.post('more/transaction/order',data); },
            async GET_DETAIL(orderId){ return await http.get('more/transaction/order/apps/'+orderId)},
        },
        WISHLIST: {
            async GET(data){ return await http.get('more/user/wishlist?'+$.param(data)); },
            async SAVE(data){ return await http.post('more/user/wishlist',data);},
            async DELETE(id){ return await http.delete('/more/user/wishlist/'+id)},
            async SYNC_GUEST(data){ return await http.post('/more/user/wishlist/sync-guest',data)},
        }
    },
    WS_INSPIRASI:{
        async FILTER_ROOM(){return await http.get('uni/inspiration/room')},
        async GET_LIST(data){return await http.get('/uni/inspiration?'+$.param(data))},
        async GET_DETAIL(inspirasi_id){return await http.get('uni/inspiration/'+inspirasi_id)}
    },
    WS_NOTIFICATION:{
        async GET_LIST(data){return await http.get('more/user/notification?'+$.param(data))},
        async SET_VIEW(data){ return await http.post('more/user/notification/update-read',data)},
        async SET_REMINDER(data){ return await http.post('more/setting/reminder',data); },
    },
    WS_KONSULTASI:{
        async GET_CUSTOMER(data){ return await http.get('more/interior/customer?'+$.param(data)) },
        async GET_DETAIL_CUSTOMER(data){ return await http.get('more/interior/customer/'+data) },
        async GET_DATA_PROP(){ return await http.get('more/project/data/property')},
        async GET_DATA_LOCATION(){ return await http.get('more/project/data/location')},
        async SAVE(data){ return await http.post('more/project',data)}
    },
    WS_NEWS:{
        async GET_TAGS(){ return await http.get('uni/news/tags')},
        async GET_CATEGORIES(){ return await http.get('uni/news/categories')},
        async GET_TAGS_TOP(){ return await http.get('uni/news/tags-top')},
        async GET_SELECTED(data){ return await http.get('uni/news/selected?'+$.param(data))},
        async GET_LIST(data){ return await http.get('uni/news?'+$.param(data))},
        async GET_DETAIL(slug){return await http.get('uni/news/'+slug)},
        async GET_PRODUCT(data){return await http.get('uni/news/products?'+$.param(data))},
    },
    WS_CHATS:{
        async GET_CHATS(data){ return await http.get('more/user/chat?'+$.param(data))},
        async SAVE(data){ return await http.post('more/user/chat/store',data)},
        async GET_INBOX(){ return await http.get('more/user/chat/unread')},
        async SET_READ(data){ return await http.post('more/user/chat/read',data)}
    },
    WS_MOREVERSE: {
        async GET_MAPS(data) { return await http.get('moreverse/map?'+$.param(data))},
        async GET_MITRA(data) { return await http.get('moreverse/mitra?'+$.param(data))},
        async GET_EXHIBITION(slug) { return await http.get('moreverse/exhibition/'+slug)},
        async GET_CATALOGUE() { return await http.get('moreverse/catalogue')},
        async GET_VIDEO() { return await http.get('moreverse/video')},
        async GET_ACTIVITY(data) { return await http.get('moreverse/activities?'+$.param(data))},
        async GET_NOTIFICATIONS(){ return await http.get('moreverse/notif') },
        async SET_REMINDER(activity_id){ return await http.get('moreverse/reminder?activities='+activity_id) },
    }
}

export default apiurl