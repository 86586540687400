import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'
import http from '@/lib/axios';

Vue.use(VueRouter)

const authenticatedIslogin = (to, from, next, customPath = null) => {

	if (!store.getters['auth/authenticated']) {
		if (customPath) {
			next({
				path: customPath,
				query: { redirect: to.fullPath }
			});
		} else {
			next({
				path: "/login",
				query: { redirect: to.fullPath }
			});
		}
	} else {
		next();
	}
}

const routes = [
	{
		path: '/',
		name: 'more-homepage',
		component: Home,
		meta: {
			title: "Toko Furniture Rumah Tangga Online | MORE Furniture",
			description: "Jual beragam furniture rumah tangga online berkualitas dan desain minimalis, gratis ongkir tanpa syarat",
			enterClass: "animate__animated animate__fadeInLeft",
			leaveClass: "animate__animated animate__fadeOutLeft",
		},
		beforeEnter: (to, from, next) => {
			store.commit("product/list/resetFilter", true);
			next();
		},
	},
	// {
	// 	path: '/moreversary',
	// 	name: 'moreversary',
	// 	component: () => import('../views/Moreversary.vue'),
	// 	meta: {
	// 		title: "Moreversary #HematTiada2nya | MORE Furniture",
	// 		description:"Deskripsi: Ikuti berbagai keseruan di Moreversary. Dapatkan informasi promo, giveaway, lelang furniture, gebyar undian hingga voucher special hanya untukmu",
	// 	},
	// },
	// {
	// 	path: '/moreversary-undian',
	// 	name: 'moreversary-undian',
	// 	component: () => import('../views/MoreversaryUndian.vue'),
	// 	meta: {
	// 		title: "Gebyar Undian Berhadiah Moreversary #HematTiada2nya | MORE Furniture",
	// 		description:"Ikuti gebyar undian Moreversary berhadiah total 22 juta rupiah. Kumpulkan kupon undian dan menangkan berbagai hadiah furniture menarik",
	// 	},
	// },
	{
		path: '/moreverse',
		name: 'moreverse',
		component: () => import('../views/moreverse/Index.vue'),
		meta: {
			title: "Furniture Virtual Event | MOREVERSE",
			description: "Furniture Virtual Event Pertama Kebanggaan Keluarga Indonesia, rasakan pengalaman baru berbelanja furniture secara virtual di MOREVERSE",
		}
	},
	{
		path: '/download',
		name: 'more-download',
		component: () => import('../views/Download.vue'),
		meta: {
			title: "Tentang Kami | MORE Furniture",
			description:"Mitra Olympic Retail Experience (MORE) adalah solusi terbaru dari Mitra-Mitra Olympic untuk berbelanja furnitur kapan saja dan dimana saja",
		},
	},
	{
		path: '/apple-login',
		name: 'more-apple-login',
		component: () => import('../views/auth/Apple.vue'),
		meta: {
			title: "Tentang Kami | MORE Furniture",
			description:"Mitra Olympic Retail Experience (MORE) adalah solusi terbaru dari Mitra-Mitra Olympic untuk berbelanja furnitur kapan saja dan dimana saja",
		},
	},
	{
		path: '/about',
		name: 'more-about',
		component: () => import('../views/About.vue'),
		meta: {
			title: "Tentang Kami | MORE Furniture",
			description:"Mitra Olympic Retail Experience (MORE) adalah solusi terbaru dari Mitra-Mitra Olympic untuk berbelanja furnitur kapan saja dan dimana saja",
		},
	},
	{
		path: '/about-apps',
		name: 'more-about-apps',
		component: () => import('../views/AboutApps.vue'),
		meta: {
			title: "Tentang Kami | MORE Furniture",
			description:"Mitra Olympic Retail Experience (MORE) adalah solusi terbaru dari Mitra-Mitra Olympic untuk berbelanja furnitur kapan saja dan dimana saja",
		},
	},
	{
		path: '/faq',
		name: 'more-faq',
		component: () => import('../views/FAQ.vue'),
		meta: {
			title: "Pusat Bantuan | MORE Furniture",
			description:"Pusat bantuan seputar pertanyaan yang sering diajukan oleh pembeli beserta jawabannya",
		},
	},
	{
		path: '/faq-apps',
		name: 'more-faq-apps',
		component: () => import('../views/FAQApps.vue'),
		meta: {
			title: "Pusat Bantuan | MORE Furniture",
			description:"Pusat bantuan seputar pertanyaan yang sering diajukan oleh pembeli beserta jawabannya",
		},
	},
	{ 
		path: '/konsultasi', 
		redirect: '/desain-interior', 
		redirectParams: { 
		  responseCode: 301 
		} 
	},
	
	{
		path: '/desain-interior/surabaya',
		name: 'interior',
		component: () => import('../views/interior/Interior.vue'),
		meta: {
			title: "Jasa Desain Interior Surabaya & Sekitarnya | MORE Furniture",
			description:"MORE Custom menawarkan jasa desain interior Surabaya siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah",
		},
	},
	{
		path: '/desain-interior/gresik',
		name: 'interior',
		component: () => import('../views/interior/Interior.vue'),
		meta: {
			title: "Jasa Desain Interior Gresik & Sekitarnya | MORE Furniture",
			description:"MORE Custom menawarkan jasa desain interior Gresik siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah",
		},
	},
	{
		path: '/desain-interior/sidoarjo',
		name: 'interior',
		component: () => import('../views/interior/Interior.vue'),
		meta: {
			title: "Jasa Desain Interior Sidoarjo & Sekitarnya | MORE Furniture",
			description:"MORE Custom menawarkan jasa desain interior Sidoarjo siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah",
		},
	},

	{
		path: '/desain-interior/:slug',
		name: 'InteriorCustomer',
		component: () => import('../views/interior/InteriorCustomer.vue'),
		meta: {
			title: "Jasa Desain Interior, Rumah Minimalis | MORE Furniture",
			description:"Nikmati jasa desain interior rumah sesuai kebutuhan Anda, mari ciptakan ruangan aman dan nyaman untuk keluarga",
		},
	},
	{
		path: '/portfolio/:slug',
		name: 'InteriorDetail',
		component: () => import('../views/interior/InteriorDetail.vue'),
		meta: {
			title: "Jasa Desain Interior, Rumah Minimalis | MORE Furniture",
			description:"Nikmati jasa desain interior rumah sesuai kebutuhan Anda, mari ciptakan ruangan aman dan nyaman untuk keluarga",
		},
	},
	{
		path: '/desain-interior',
		name: 'interior',
		component: () => import('../views/interior/Interior.vue'),
		meta: {
			title: "Jasa Desain Interior Surabaya & Sekitarnya | MORE Furniture",
			description:"MORE Custom menawarkan jasa desain interior Surabaya siap membuat desain interior rumah, kantor, dan ruangan lainnya menjadi lebih indah dan berkesan mewah",
		},
	},
	{
		path: '/snk',
		name: 'Snk',
		component: () => import('../views/Snk.vue'),
		meta: {
			title: "SYARAT DAN KETENTUAN"
		},
	},
	{
		path: '/snk-apps',
		name: 'Snk Apps',
		component: () => import('../views/SnkApps.vue'),
		meta: {
			title: "SYARAT DAN KETENTUAN"
		},
	},
	{
		path: '/artikel',
		name: 'more-article',
		component: () => import('../views/artikel/Artikel.vue'),
		meta: {
			title: "Inspirasi Hunian Terkini | MORE Furniture",
			description:"Temukan beragam informasi desain interior rumah minimalis dan tips lainnya di Blog MORE Furniture",
		},
	},
	{
		path: '/artikel/:slug',
		name: 'ArtikelDetail',
		component: () => import('../views/artikel/ArtikelDetail.vue'),
	},
	{
		path: '/artikel/category/:slug',
		name: 'ArtikelSearch',
		component: () => import('../views/artikel/ArtikelSearch.vue'),
	},
	{
		path: '/products/:filter?',
		name: 'Products',
		meta: {
			title: 'Belanja Furniture'
		},
		component: () => import('../views/product/Products.vue'),
		beforeEnter: (to, from, next) => {
			// console.log(from);
			if (to.params.filter) {
				let q = _.mapKeys(queryString.parse(to.params.filter), (value, key) => key.replace("[]", ""));
				store.commit("product/list/resetFilter", true);
				store.commit("product/list/setFiltered", q);
			}

			store.commit("product/list/setCopiedFilter", from.name != null ? false : true);
			next();
		},
	},
	{
		path: '/find/:slug',
		name: 'ProductFind',
		component: () => import('../views/product/ProductFind.vue'),
	},
	{
		path: '/product/sale',
		name: 'ProductSale',
		component: () => import('../views/product/ProductSale.vue'),
	},
	// Kategori
	{
		path: '/category/springbed-kasurbusa',
		name: 'CategorySpringbed',
		component: () => import('../views/category/CategorySpringbed.vue'),
	},
	{
		path: '/category/springbed-dalam-box',
		name: 'CategoryLaxy',
		component: () => import('../views/category/CategoryLaxy.vue'),
	},
	
	{
		path: '/category/:slug',
		name: 'CategoryFind',
		component: () => import('../views/category/CategoryFind.vue'),
	},
	//End Kategori
	{
		path: '/product/:slug',
		name: 'ProductDetail',
		component: () => import('../views/product/Detail.vue'),
	},
	{
		path: '/bundle/:slug?',
		name: 'BundleDetail',
		component: () => import('../views/product/Bundle.vue'),
	},
	{
		path: '/forgotPassword/:token?',
		name: 'ForgotPassword',
		component: () => import('../views/auth/ForgotPassword.vue'),
		meta: {
			title: "Atur Ulang Kata sandi"
		},
	},
	{
		path: '/login',
		name: 'more-login',
		component: () => import('../views/auth/Login.vue'),
		meta: {
			title: "Masuk Akun | MORE Furniture",
			description:"Login sekarang ke MORE Furniture untuk menemukan berbagai perabotan rumah tangga dengan harga terjangkau",
		},
		beforeEnter: (to, from, next) => {
			if (!store.getters['auth/authenticated']) {
				next();
			} else {
				next("/");
			}
		},

	},
	{
		path: '/register',
		name: 'more-register',
		component: () => import('../views/auth/Register.vue'),
		meta: {
			title: "Daftar Akun | MORE Furniture",
			description:"Daftar / Register akun MORE Furniture sekarang. Dapatkan lebih banyak promo dan voucher diskon menarik",
		},
		beforeEnter: (to, from, next) => {
			if (!store.getters['auth/authenticated']) {
				next();
			} else {
				next("/");
			}
		},
	},
	{
		path: '/more-retail-experience',
		name: 'Retail',
		component: () => import('../views/RetailExperience.vue'),
		meta: {
			title: "More Retail Experience | MORE Furniture",
			description:"More Retail Experience"
		},
	},
	{
		path: '/promo/:slug',
		name: 'Promotion',
		component: () => import('../views/promotion/Promotion.vue'),
		meta: {
			title: "SALE"
		},
	},
	{
		path: '/promotion',
		name: 'PromotionActive',
		component: () => import('../views/promotion/PromotionActive.vue'),
		meta: {
			title: "Promosi"
		},
	},
	{
		path: '/moreversary',
		name: 'moreversary',
		component: () => import('../views/promotion/Moreversary.vue'),
		meta: {
			title: "MOREVERSARY 3th #3uforiaBelanja | MORE Furniture",
			description:"Dapatkan berbagai promo buy 1 get 1, tebus murah, night shopping, hadiah spesial, mega flash sale, birthday gift dan banyak lagi. Cek sekarang!",
		},
	},
	{
		path: '/moreversary-retail',
		name: 'MoreversaryRetail',
		component: () => import('../views/promotion/MoreversaryRetail.vue'),
		meta: {
			title: "MOREVERSARY RETAIL 3th #3uforiaBelanja | MORE Furniture",
			description:"Dapatkan berbagai promo buy 1 get 1, tebus murah, night shopping, hadiah spesial, mega flash sale, birthday gift dan banyak lagi. Cek sekarang!",
		},
	},
	{
		path: '/invoice',
		name: 'Invoice',
		component: () => import('../views/profile/Invoice.vue'),
		beforeEnter: authenticatedIslogin,
		meta: {
			title: "Detail Tagihan"
		},
	},
	{
		path: '/invoice-print/:ordersn',
		name: 'InvoiceNoAuth',
		component: () => import('../views/profile/InvoiceNoAuth.vue'),
		meta: {
			title: "Invoice"
		},
	},
	{
		path: '/plain-menu',
		name: 'PlainMenu',
		component: () => import('../views/profile/PlainMenu.vue'),
		beforeEnter: authenticatedIslogin,
		meta: {
			title: "Menu Profile"
		},
	},
	{
		path: '/verify-email/:id',
		name: 'verify',
		beforeEnter: async (to, from, next) => {
			let _url = 'mail/verification/' + to.params.id;
			await http.get(_url).then(success => {
				next("/");
			}).catch(error => {
				next("/404");
			});
		},
		meta: {
			title: "Alamat Pengiriman"
		}
	},
	{
		path: '/vr/:slug',
		name: 'more-inspiration',
		component: () => import('../views/inspirasi/ListInpirasi.vue'),
		meta: {
			title: "Inspirasi Ruangan | MORE Furniture",
			description:"Inspirasi set furniture dari MORE Furniture untuk kamar tidur, ruang tamu dan dapur. Temukan dekorasi kamar dengan desain modern dan minimalis"
		}
	},
	{
		path: '/inspirasi/:slug',
		name: 'more-inspiration',
		component: () => import('../views/inspirasi/ListInpirasi.vue'),
		meta: {
			title: "Inspirasi Ruangan | MORE Furniture",
			description:"Inspirasi set furniture dari MORE Furniture untuk kamar tidur, ruang tamu dan dapur. Temukan dekorasi kamar dengan desain modern dan minimalis"
		}
	},
	{
		path: '/inspirasi-detail/:slug',
		name: 'DetailInpirasi',
		component: () => import('../views/inspirasi/DetailInpirasi.vue'),
		meta: {
			title: "Inspirasi"
		}
	},
	{
		path: '/offline-store',
		name: 'OfflineStore',
		component: () => import('../views/inspirasi/OfflineStore.vue'),
		meta: {
			title: "Offline Store"
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('../views/profile/Menu.vue'),
		redirect: { name: 'Account' },

		children: [
			{
				path: '',
				name: 'Account',
				component: () => import('../views/profile/InfoAccount.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Informasi Akun"
				}
			},
			{
				path: 'wishlist',
				name: 'Wishlist',
				component: () => import('../views/profile/Wishlist.vue'),
				beforeEnter: authenticatedIslogin,
				meta: { title: "Wishlist" }
			},
			{
				path: 'orders',
				name: 'OrderList',
				component: () => import('../views/profile/DashboardOrder.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Pesanan"
				}
			},
			{
				path: 'order/:ordersn',
				name: 'OrderDetail',
				component: () => import('../views/profile/OrderDetail.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Detail Pesanan"
				},
			},
			{
				path: 'pay',
				name: 'Orderpay',
				component: () => import('../views/profile/Pay.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Bayar"
				},
			},
			{
				path: 'review',
				name: 'OrderReview',
				component: () => import('../views/profile/OrderReview.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Review Pesanan"
				},
			},
			{
				path: 'complain',
				name: 'OrderComplain',
				component: () => import('../views/profile/OrderComplain.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Komplain Pesanan"
				},
			},
			{
				path: 'complain-detail',
				name: 'OrderComplainDetail',
				component: () => import('../views/profile/OrderComplainDetail.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Detail Komplain Pesanan"
				},
			},
			{
				path: 'event',
				name: 'Event',
				component: () => import('../views/profile/Event.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Event"
				}
			},
			{
				path: 'address',
				name: 'Address',
				component: () => import('../views/profile/DashboardAddress.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Alamat Pengiriman"
				}
			},
			{
				path: 'dropshipper',
				name: 'Dropshipper',
				component: () => import('../views/profile/DashboardDropshipper.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Daftar Dropshipper"
				}
			},
			{
				path: 'banks',
				name: 'Banks',
				component: () => import('../views/profile/BankAccounts.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Daftar Rekening"
				},
			},
			{
				path: 'notifications',
				name: 'Notifications',
				component: () => import('../views/profile/Notification.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Daftar Notification"
				},
			},
			{
				path: 'password',
				name: 'Password',
				component: () => import('../views/profile/ChangePassword.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Ubah Kata Sandi"
				},
			},
			{
				path: 'vouchers',
				name: 'vouchers',
				component: () => import('../views/profile/DashboardVoucher.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Voucher Saya"
				},
			},
			{
				path: 'chats',
				name: 'chats',
				component: () => import('../views/profile/Chats.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Chats Saya"
				},
			},

		]
	},
	{
		path: '/cart',
		name: 'Cart',
		component: () => import('../views/transaction/Cart.vue'),
		meta: {
			title: "Keranjang Belanja",
		},
		// beforeEnter: (to, from, next) => authenticatedIslogin(to,from,next),
	},
	{
		path: '/buy',
		name: 'Buy',
		beforeEnter: authenticatedIslogin,
		component: () => import('../views/transaction/checkout/Buy.vue'),
		meta: {
			title: "Beli Langsung",
		},
		// beforeEnter: (to, from, next) => authenticatedIslogin(to,from,next),
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: () => import('../views/transaction/checkout/Index.vue'),
		redirect: { name: 'Shipping' },
		children: [
			{
				path: '',
				name: 'Shipping',
				component: () => import('../views/transaction/checkout/Shipping.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Pengiriman Pesanan"
				}
			},
			{
				path: 'payment',
				name: 'Payment',
				component: () => import('../views/transaction/checkout/Payment2.vue'),
				beforeEnter: authenticatedIslogin,
				meta: { title: "Metode Pembayaran" }
			},
			{
				path: 'review',
				name: 'Review',
				component: () => import('../views/transaction/checkout/Review.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Ulasan Pesanan"
				},
			},
			{
				path: 'information/:ordersn',
				name: 'Information',
				component: () => import('../views/transaction/checkout/Information.vue'),
				meta: {
					title: "Informasi Pembayaran"
				},
			},
			{
				path: 'success',
				name: 'Success',
				component: () => import('../views/transaction/checkout/Success.vue'),
				beforeEnter: authenticatedIslogin,
				meta: {
					title: "Selesaikan Pembayaran"
				},
			},
			{
				path: 'thanks',
				name: 'Thanks',
				beforeEnter: (to, from, next, customPath = null) => {
					// console.log("to",to);
					// console.log("from",from);
					// console.log("next", next);
					const device = localStorage.getItem("type_device");
					// console.log(device);
					if(device === 'Browser'){
						next({ path: "/profile/order/" + to.query.bill_no });
					}else{
						next({ path: "/checkout/information/" + to.query.bill_no });
					}
				}
			},
		]
	},
	{
		path: '/sb/warranty-registration',
		beforeEnter(to, from, next) {
			// Put the full page URL including the protocol http(s) below
			window.location.replace("http://laxybed.morefurniture.id")
		}
	},
	{
		path: '/refreshauthlazada',
		beforeEnter(to, from, next) {
			// Put the full page URL including the protocol http(s) below
			window.location.replace("http://147.139.178.112/multichannel/get-token-lazada")
		}
	},
	{
		path: '/404',
		name: 'notFound',
		component: () => import('../views/error/notFound.vue'),
		meta: {
			title: "Halaman Tidak di Temukan",
		}
	},
	{
		path: '*',
		name: 'CatchAll',
		component: Home
	}
]

const router = new VueRouter({
	mode: 'history',
	hash: false,
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	var title = 'Toko Furniture Rumah Tangga Online | MORE Furniture';
	var description = 'Jual beragam furniture rumah tangga online berkualitas dan desain minimalis, gratis ongkir tanpa syarat';
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : title;
	window.document.description = toRoute.meta && toRoute.meta.title ? toRoute.meta.description : description;

	next();
})

router.afterEach((to) => {
	if(to.name == 'moreverse' || to.name == 'ProductDetail') return;
	router.app.$store.commit("product/detail/setProduct", null);
})

export default router;