import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Axios from 'axios'
import moment from 'moment';
import alert from './components/alert/alert'
import alertError from './components/alert/error'
import alertInfo from './components/alert/info'
import alertSuccess from './components/alert/success'
import LoadingImg from './components/loading'
import Popup from './components/popup/Popup'
import VueToast from 'vue-toast-notification';
import GAuth from 'vue-google-oauth2';
import FacebookAuth from 'vue-facebook-oauth';
import VueGtag from "vue-gtag";
import BlockUI from 'vue-blockui';
import VueFacebookPixel from 'vue-facebook-pixel';
import VueMeta from 'vue-meta';
import _ from 'lodash';
import {VueMasonryPlugin} from 'vue-masonry';
import VueLazyload from 'vue-lazyload'
import VueHead from 'vue-head'
import browserDetect from "vue-browser-detect-plugin";
// import VueAppleLogin from 'vue-apple-login';

import './assets/js/select2/css/select2.min.css';
import './assets/js/datepicker/daterangepicker.css';
import "./assets/css/jquery-ui.min.css";
import "./assets/css/style.css";
import "./assets/css/style-2023.css";
import "./assets/js/slick/slick.css";
// import "./assets/css/style-edit.css";
import "./assets/css/indexnew.css"
import "./assets/css/indexnew-v2.css"

import './assets/js/floating-wpp/floating-wpp.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import OneSignalVue from 'onesignal-vue';


// import * as Sentry from "@sentry/vue"; //New Sentry

window.$ = window.jQuery = require('jquery')
window.Swal = window.Swal = require('./assets/js/swal/sweetalert2@10.js')
window.queryString = require('query-string');
window.WOW = require('./assets/js/wow.js');
window.daterangepicker = require('./assets/js/datepicker/daterangepicker.js');

require('./assets/js/jquery-ui.min.js')
require('./assets/js/jquery.watermark.min.js')
require("./assets/js/slick/slick.min.js");
require('./assets/js/floating-wpp/floating-wpp.min.js')
require('./assets/js/select2/js/select2.min.js')
require('./assets/js/jquery.countdown.js');

require('./lib/FIrebase.js');

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
  // plugin_name: 'morefurniture.id',
}
Vue.use(browserDetect);
Vue.use(VueHead)
Vue.use(VueFacebookPixel)
Vue.use(VueToast)
Vue.use(BlockUI)
Vue.use(GAuth, gauthOption)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
});
Vue.use(VueMasonryPlugin)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});


Vue.use(VueLazyload, {
  preLoad: 1.2,
  error: require('./assets/logo.png'),
  loading: require('./assets/loading-3.gif'),
  // loading: require('./assets/loading-27.gif'),
  attempt: 1
})

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('alert-error', alertError)
Vue.component('alert-info', alertInfo)
Vue.component('alert-success', alertSuccess)
Vue.component('Alert', alert)
Vue.component('LoadingImg', LoadingImg)
Vue.component('Popup', Popup)


Vue.prototype.$http = Axios;

Vue.config.productionTip = false
Vue.analytics.fbq.init(process.env.VUE_APP_FP_TOKEN, {});

Vue.mixin({
  methods: {
    asset: str => process.env.VUE_APP_BASE_CDN_URL+'MORE/'+str,
    assets: str => process.env.VUE_APP_BASE_CDN_URL+'MORE/asset/'+str,
    icons: str => process.env.VUE_APP_BASE_CDN_URL+'MORE/icon/'+str,
    assetCustom: (str) => process.env.VUE_APP_BASE_CDN_URL+'MORE/custom/'+str,
    assetIcon: (str) => process.env.VUE_APP_BASE_CDN_URL+'MORE/icon/'+str,
    noImage:()=> process.env.VUE_APP_BASE_CDN_URL+'images/noimage.png',
    analytics: (events,obj)=> {
      Vue.$gtag.event(events, obj);
      Vue.analytics.fbq.event(events, obj);
    }
  }
})

// const time = new Date();
// Vue.use(VueAppleLogin, {
//   clientId: 'HJ4U425M86.com.olympic.more',
//   scope: 'name email',
//   redirectURI: 'http://localhost:8080',
//   state: time.toString(),
//   usePopup: true,
// });


// Sentry.init({
//   Vue,
//   dsn: "https://0d8e7645191e5209c1c1066bcbc00a4c@o4505821359243264.ingest.sentry.io/4505821362913280",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

Vue.use(OneSignalVue);

new Vue({
  router,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: '63935fc3-f0a7-4348-9db9-82042720a87d' });
  },
  store:store,
}).$mount('#app')
