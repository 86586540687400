<template>
    <div>
        <header>
            <nav class="navbar">
                
                <!-- LOGO -->
                <div class="logo">
                    <a href="/" style="margin-right:0px;">
                        <img class="logo-header" src="https://cdn.morefurniture.id/MORE/asset/morelogo_nd.png">
                    </a>
                </div>
                
                <div class="search-mobile-header">
                    <form class="example form-search-mobile searchListMainDiv"  @submit.prevent="searching()">
                        <input type="text" v-on:keypress="searchProduct()"  placeholder="Pencarian Produk" autocomplete="off" aria-autocomplete="none" name="search" value="" v-model="search">
                        <button type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                        <ul class="ul-search" style="width:72%;margin-top:40px;">
                        <li v-for="result in results" :key="result.keyword" @click="searchKeyword(result.keyword)" >{{ result.keyword }}</li>
                        </ul>
                    </form>
                </div>
                
                <a href="/cart" style="width:20px;display:none;" class="search-addtocart-header">
                    <i class="fa fa-shopping-cart" style="width:100%;font-size:20px"></i>
                </a>
                <input type="checkbox" id="menu" name="menu" class="m-menu__checkbox">
                <label class="m-menu__toggle" for="menu">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#424F61" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </label>
                <label class="m-menu__overlay" for="menu"></label>
                <div class="m-menu" >
                    <div class="m-menu__header">
                        
                        <span>Menu</span>
                        <label class="m-menu__toggle_close" for="menu">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#424F61" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </label>
                    </div>
                    <menu>
                        <input type="checkbox" id="drop" />
                        <ul class="menu-mobile">
                            <!-- <li>
                                <a  href="/moreversary">
                                    <b style="display:flex;align-items:center;color:#CA352B;">PROMO MOREVERSARY &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/discount-red.png" width="15" height="15"></b>
                                </a>
                            </li> -->
                            <li>
                                <a  href="/promotion">
                                    <b style="display:flex;align-items:center;color:#CA352B;">PROMO &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/discount-red.png" width="15" height="15"></b>
                                </a>
                            </li>
                            <li>
                                <label for="drop-1" class="toggle toggle-parent">Semua Produk <span class="menu-dropdown-symbol">&#10095;</span></label>
                                <a href="#">Semua Produk</a>
                                <input type="checkbox" id="drop-1"/>
                                <ul class="drop-1 ul-parent">
                                    
                                    <li>
                                        <a  :href="'/products/type=terbaru'">
                                            <b style="display:flex;align-items:center;">Produk Terbaru &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/new.png" width="15" height="15">
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a  :href="'/products/type=terlaris'">
                                            <b style="display:flex;align-items:center;">Produk Terlaris &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/fire.png" width="15" height="15">
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a  :href="'/products/category_group=123'">
                                            <b style="display:flex;align-items:center;">Paket Hemat &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/discount-red.png" width="15" height="15">
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a  :href="'/products/q=HOT'">
                                            <b style="display:flex;align-items:center;">Hot Product &nbsp;<img src="https://cdn.morefurniture.id/MORE/icon/fire.png" width="15" height="15">
                                            </b>
                                        </a>
                                    </li>
                                    <li v-for="category in categoriesGroup" :key="category.id">
                                        <!-- <a v-if="category.name != 'Springbed'" :href="'/products/category_group='+category.id" >{{category.name}}</a> -->
                                        <a v-if="category.name != 'Springbed'" :href="'/category/'+category.name.toLowerCase().replaceAll(' ','-')" >{{category.name}}</a>
                                        <a v-if="category.name == 'Springbed'" :href="'/category/springbed-kasurbusa'" >{{category.name}}</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <label for="drop-2" class="toggle toggle-parent">Ruangan <span class="menu-dropdown-symbol">&#10095;</span></label>
                                <a href="#">Ruangan</a>
                                <input type="checkbox" id="drop-2"/>
                                <ul class="drop-2 ul-parent">
                                    <li v-for="(category) in categories" :key="category.id">
                                        <label :for="'drop-3-'+category.id" class="toggle toggle-child">{{category.name}} <span class="menu-dropdown-symbol">&#10095;</span> </label>
                                        <a href="#">{{category.name}}</a>      
                                        <input type="checkbox" :id="'drop-3-'+category.id"/>
                                        <ul :class="'drop-3-'+category.id+' ul-child'">
                                            <li v-for="(item2) in category.r_subs" :key="item2.id">
                                                <!-- <a v-if="item2.name != 'Springbed'" :href="'/products/categories%5B%5D='+item2.id" >{{ item2.name }}</a> -->
                                                <a v-if="item2.name != 'Springbed'" :href="'/category/'+item2.name.toLowerCase().replaceAll(' ','-')" >{{ item2.name }}</a>
                                                <a v-if="item2.name == 'Springbed'" :href="'/category/springbed-kasurbusa'" >{{ item2.name }}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <label for="drop-4" class="toggle toggle-parent">Brand <span class="menu-dropdown-symbol">&#10095;</span></label>
                                <a href="#">Brand</a>
                                <input type="checkbox" id="drop-4"/>
                                <ul class="drop-4 ul-parent">
                                    <li>
                                        <a :href="'/products/brands=OLYMPIC'">
                                            <b>Olympic 
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=OLYMPIC SPRINGBED'">
                                            <b>Olympic Springbed
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=OLYMPIC FOAM'">
                                            <b>Olympic Foam
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=OLYMSTEEL'">
                                            <b>Olymsteel 
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=OLYMPLAST'">
                                            <b>Olymplast 
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=PROCELLA SOFA'">
                                            <b>Procella Sofa
                                            </b>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/products/brands=PROCELLA SPRINGBED'">
                                            <b>Procella Springbed
                                            </b>
                                        </a>
                                    </li>
                                    
                                    <!-- <li>
                                        <a :href="'/products/brands=LAXY BED'">
                                            <b>Laxy Bed 
                                            </b>
                                        </a>
                                    </li> -->
                                    <!-- <li>
                                        <a :href="'/products/brands=FRONTLINE'">
                                            <b>Frontline
                                            </b>
                                        </a>
                                    </li> -->
                                </ul>
                            </li>
                            <li>
                                <a href="https://affiliate.morefurniture.id/" target="_blank" >Affiliate</a>
                            </li>
                            <li>
                                <a  href="/desain-interior">Desain Interior</a>
                            </li>
                            <li>
                                <a  href="/artikel">Artikel</a>
                            </li>
                        </ul>
                    </menu>
                </div>
                <ul class="nav-links">
                    <!-- USING CHECKBOX HACK -->
                    <!-- NAVIGATION MENUS -->
                    <form class="example form-search searchListMainDiv"  @submit.prevent="searching()" >
                        <input type="text" v-on:keypress="searchProduct()"  placeholder="Pencarian Produk" autocomplete="off" aria-autocomplete="none" name="search" value="" v-model="search">
                        <button type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                        <ul class="ul-search">
                            <li v-for="result in results" :key="result.keyword" @click="searchKeyword(result.keyword)" >{{ result.keyword }}</li>
                        </ul>
                        
                    </form>
                    <!-- The navigation menu -->
                    <div class="navbar">
                        <!-- <div class="subnav">
                            <a class="subnavbtn" href="/moreversary" style="color:#CA352B;font-weight: 800;"><b>MOREVERSARY</b></a>
                        </div> -->
                        <div class="subnav">
                            <a class="subnavbtn" href="/promotion" style="color:#CA352B;font-weight: 800;"><b>PROMO</b></a>
                        </div>
                        <div class="subnav">
                            <button class="subnavbtn">Semua Produk <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="subnav-content">
                                <div class="subnav-detail">
                                    <div class="subnav-detail-child">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/type=terbaru'">
                                                <b>Produk Terbaru <img src="https://cdn.morefurniture.id/MORE/icon/new.png" width="10">
                                                </b>
                                            </a>
                                        </div>
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/type=terlaris'">
                                                <b>Produk Terlaris <img src="https://cdn.morefurniture.id/MORE/icon/fire.png" width="10">
                                                </b>
                                            </a>
                                        </div>
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/category_group=123'">
                                                <b>Paket Hemat <img src="https://cdn.morefurniture.id/MORE/icon/discount-red.png" width="10">
                                                </b>
                                            </a>
                                        </div>
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/q=HOT'">
                                                <b>Hot Product <img src="https://cdn.morefurniture.id/MORE/icon/fire.png" width="10">
                                                </b>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child">
                                        <div v-for="category in categoriesGroup.slice(0, 3)" :key="category.id" class="child-ahref">
                                            <!-- <a class="a-header-nav" :href="'/products/category_group='+category.id">{{category.name}}</a> -->
                                            <a class="a-header-nav" :href="'/category/'+category.name.toLowerCase().replaceAll(' ','-')">{{category.name}}</a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child">    
                                        <div v-for="category in categoriesGroup.slice(3, 6)" :key="category.id" class="child-ahref">
                                            <!-- <a class="a-header-nav" :href="'/products/category_group='+category.id">{{category.name}}</a> -->
                                            <a class="a-header-nav" :href="'/category/'+category.name.toLowerCase().replaceAll(' ','-')">{{category.name}}</a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child">
                                        <div v-for="category in categoriesGroup.slice(6, 9)" :key="category.id" class="child-ahref">
                                            <!-- <a class="a-header-nav" :href="'/products/category_group='+category.id">{{category.name}}</a> -->
                                            <a class="a-header-nav" :href="'/category/'+category.name.toLowerCase().replaceAll(' ','-')">{{category.name}}</a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child">
                                        <div v-for="category in categoriesGroup.slice(9,12)" :key="category.id" class="child-ahref">
                                            <!-- <a v-if="category.name != 'Springbed'" class="a-header-nav" :href="'/products/category_group='+category.id">{{category.name}}</a> -->
                                            <a v-if="category.name != 'Springbed'" class="a-header-nav" :href="'/category/'+category.name.toLowerCase().replaceAll(' ','-')">{{category.name}}</a>
                                            <a v-if="category.name == 'Springbed'" class="a-header-nav" :href="'/category/springbed-kasurbusa'">{{category.name}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="subnav">
                            <button class="subnavbtn">Ruangan <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="subnav-content">
                                <div class="subnav-detail">
                                    <div class="subnav-detail-child" v-for="(category) in categories" :key="category.id">
                                        <span class="h2-header">{{category.name}}</span>
                                        <div class="child-ahref"  v-for="(item2) in category.r_subs" :key="item2.id">
                                            <!-- <a class="a-header-nav" :href="'/products/categories%5B%5D='+item2.id" >{{ item2.name}}</a> -->
                                            <a class="a-header-nav" :href="'/category/'+item2.name.toLowerCase().replaceAll(' ','-')" >{{ item2.name}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="subnav">
                            <button class="subnavbtn">Brand <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="subnav-content" >
                                <div class="subnav-detail" style="justify-content: center;">
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav"  :href="'/products/brands=OLYMPIC'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/olympic-furniture.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav"  :href="'/products/brands=OLYMPIC FOAM'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/olympic-foam.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav"  :href="'/products/brands=OLYMPIC SPRINGBED'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/olympic-springbed.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=OLYMSTEEL'">
                                                <img src="https://asset.morefurniture.id/BRAND/LOGO/olymsteel.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=OLYMPLAST'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/olymplast.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=PROCELLA SOFA'">
                                                <img src="https://asset.morefurniture.id/BRAND/LOGO/procella-sofa.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=PROCELLA SPRINGBED'">
                                                <img src="https://asset.morefurniture.id/BRAND/LOGO/procella.webp" width="90">
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=LAXY BED'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/laxy-bed.webp" width="90">
                                            </a>
                                        </div>
                                    </div> -->
                                    <!-- <div class="subnav-detail-child-brand">
                                        <div class="child-ahref">
                                            <a class="a-header-nav" :href="'/products/brands=FRONTLINE'">
                                                <img src="https://cdn.morefurniture.id/BRAND/LOGO/frontline.webp" width="90">
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="subnav">
                            <a class="subnavbtn" href="https://affiliate.morefurniture.id/" target="_blank">Affiliate</a>
                        </div>
                        <!-- <div class="subnav">
                            <a class="subnavbtn"  href="/moreverse" target="_blank">Virtual Tour</a>
                        </div> -->
                        <div class="subnav">
                            <a class="subnavbtn"  href="/desain-interior" >Desain Interior</a>
                        </div>
                    </div>
                </ul>
                <div class="menu">
                    <div class="menu">
                        <div class="menu-item">
                            <a href="/cart" style="margin-right:0px"><i class="fa fa-shopping-cart icon-header"></i>
                                <span v-if="this.variants.length>0" class="badge-notif">{{this.variants.length}}</span>
                            </a>
                            <div class="drop-down-menu-cart">
                                <span class="ol-cart-floating__title">Baru Ditambahkan</span>
                                <small class="ol-cart-floating__total">{{this.variants.length}} Unit</small>

                                <div v-if="this.variants.length>0" class="ol-cart-floating__list">
                                    <div v-for="(item,index) in this.variants" :key="index" class="ol-cart-floating__list-item">
                                        <a :href="'/product/'+item.slug" style="float:none;">
                                            <div class="ol-cart-floating__list-item-prd">
                                                <figure class="ol-cart-floating__list-item-prd-figure">
                                                    <img v-if="item.r_uni_product_image" :src="item.r_uni_product_image.image_url" :alt="item.name_long" :title="item.name_long">
                                                    <img v-else :src="noImage()" :alt="item.name_long" :title="item.name_long">
                                                </figure>
                                                <div class="ol-cart-floating__list-item-prd-desc" style="width:265px;text-align:left;">
                                                <span><a href="javascript:void(0)" :title="item.name_long" style="float:none;margin-right:0px;font-size:13px">{{item.name_short ? item.name_short : item.name_long}}</a></span>
                                                <!-- <small>{{item.variant ? item.variant : item.color }}</small> -->
                                                <small v-if="item.is_get_combo == 1"><b>COMBO</b></small>
                                                <small v-if="item.is_get_free == 1"><b>FREE</b></small>
                                                </div>
                                            </div>
                                        </a>
                                        <span class="ol-cart-floating__list-item-price" style="margin-top:-20px;">
                                            <small  v-if="item.r_uni_product_variant_prices.more_discount && item.r_uni_product_variant_prices.more_discount != '0%'" class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_product_variant_prices.ho_normal_rp}}</small>
                                            {{ item.valid_price_rp}}
                                        </span>
                                        <a class="ol-cart-floating__list-item-close delete_notif" style="margin-right:0px;width:0%" @click="removeVariant(item)" title="Hapus"><i class="ol-fa fas fa-times"></i></a>
                                    </div>
                                    <a href="/cart" class="button button-block"  style="color:white;">Tampilkan Keranjang Belanja</a>
                                </div>
                                <!-- empty -->
                                <div v-else class="ol-cart-floating__empty">
                                    <figure class="ol-cart-floating__empty">
                                        <img :srcset="assets('empty-cart.svg')" alt="Keranjang Belanja" title="Keranjang Belanja" />
                                    </figure>
                                    <span class="ol-cart-floating__empty-label">
                                        Belum Ada Produk
                                    </span>
                                    <a href="/products/q=" class="button button-block" style="color:white;">Belanja Sekarang</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <a href="/profile/wishlist"  class="a-header">
                        <i class="fa fa-heart icon-header"></i>
                    </a>
                    <div v-if="authenticated" class="menu">
                        <div class="menu-item">
                            <i class="fa fa-user icon-header"></i>
                            <div class="drop-down-menu-profile">
                                <a href="/profile" class="a-header-nav a-href-menu-profile">
                                Informasi Akun
                                </a>
                                <br>
                                <a href="/profile/orders" class="a-header-nav a-href-menu-profile">
                                Pesanan
                                </a>
                                <!-- <br>
                                <a href="/profile/vouchers" class="a-header-nav a-href-menu-profile">
                                Voucher
                                </a> -->
                                <br>
                                <a href="javascript:void(0)" v-on:click="logout()" class="a-header-nav a-href-menu-profile">
                                Keluar
                                </a>
                            </div>
                        </div>
                        
                    </div>
                    <a v-if="!authenticated"  href="/login"  class="a-header" style="margin-left:-20px;">
                        <i class="fa fa-user icon-header"></i>
                    </a>
                </div>
            </nav>
          

            <div class="benefit">
                <div class="scrolling-words-container">
                    <div class="scrolling-words-box">
                        <ul>
                            <li>
                                <i class="fa fa-truck"></i> Gratis Ongkir Tanpa Minimum Pembelian
                            </li>
                            <li>
                                <i class="fa fa-truck"></i> Gratis Ongkir Tanpa Minimum Pembelian
                            </li>
                            <li>
                                Garansi Produk 2x24 Jam
                            </li>
                            <li>
                                <i class="fa fa-truck"></i> Gratis Ongkir Tanpa Minimum Pembelian
                            </li>
                            <li>
                                Garansi Produk 2x24 Jam
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <!-- <CategoriesHeader :categories="categories"></CategoriesHeader> -->
    </div>
      
</template>
    
    <script>
    import { debounce } from "lodash";
    import axios from "axios";
    import http from '@/lib/axios';
    import globalUrl from '@/util/globallUrl';
    const PRODUCT = globalUrl.WS_PRODUCT;
    import { mapGetters, mapActions } from "vuex";
    import CategoriesHeader from "@/components/product/CategoriesHeader";
    import { initializeApp } from 'firebase/app';
    import { getMessaging, getToken,onMessage } from "firebase/messaging";
    import { onBackgroundMessage } from "firebase/messaging/sw";
    import NotificationItemTop from '@/components/notification/NotificationItemTop';
    export default {
        name: "Header",
        components: {
            CategoriesHeader,
            NotificationItemTop,
        },
        data() {
            return {
                isDev: true,
                search: "",
                loading: true,
                fbt:null,
                queue:null,
                results: [],
            };
        },
        computed: {
            ...mapGetters("auth", ["authenticated","user","fbToken","accessToken"]),
            ...mapGetters("product/list", ["categories","filtered","categoriesGroup"]),
            ...mapGetters("transaction/cart", ["variants"]),
            ...mapGetters("notification", ["unread","data","laravelPagenation"]),
            ...mapGetters("chats", ["inbox"]),
            filteredList() {
                return this.fruits.filter(item => {
                    return (
                    this.search && item.toLowerCase().replaceAll(' ','-').includes(this.search.toLowerCase().replaceAll(' ','-'))
                    );
                });
            },
        },
        methods: {
            ...mapActions("auth", ["logout","SaveUserToken"]),
            ...mapActions("product/list", ["getCategories","getCategoriesGroup"]),
            ...mapActions("notification", ["fetchData"]),
            ...mapActions("chats", ["getInbox"]),
            ...mapActions("transaction/cart", ["save","get"]),
            searchProduct: function() {
                $(".ul-search").show();
                http.get(`uni/product/search?q=${this.search}`)
                .then((response) => {
                    this.results = response.data.data.results
                });
            },
            searchKeyword(keyword){
                this.search = keyword;
                this.searching();
            },
            hideSearch(){
                $(".ul-search").hide();
            }, 
            setMenuMobile(){
                let routeName = this.$route.name;
                switch (routeName) {
                    case "ProductDetail":
                        // console.log($(window).width());
                        if ($(window).width() < 768) {
                            $(".search-addtocart-header").css("display","block");
                        }
                        
                    break;
                    default:
                    break;
                }
            },
            setAppEnv(data) {
                this.isDev = data === "local" ? true : false;
            },
            async initFbToken(){
                const firebaseApp = initializeApp({
                    apiKey: "AIzaSyD9FfhX-T95OS89WB8uJUSWPztrMwcl7Pw",
                    authDomain: "more-306503.firebaseapp.com",
                    projectId: "more-306503",
                    storageBucket: "more-306503.appspot.com",
                    messagingSenderId: "24556042365",
                    appId: "1:24556042365:web:887c3b13ec35009d529914",
                    measurementId: "G-MRZ9DMPX3W"
                });
                const messaging = getMessaging(firebaseApp);
    
                Notification.requestPermission().then((data) =>{
                    getToken(messaging, { vapidKey: process.env.VUE_APP_VAPIDKEY }).then((currentToken) => {
                        if (currentToken) {
                            this.fbt = currentToken;
                            this.setFbToken();
                        } else {
                            Notification.requestPermission();
                        }
                    }).catch((err) => {
                        // console.error(err);
                    });
                });
    
                onMessage(messaging, (payload) => {
                    var notif = payload.notification;
                    var data = payload.data;
                    var notification = new Notification(notif.title, {body: notif.body, icon: data.image});
                    notification.close();
                    this.getData();
                    this.getInbox();
                });
            },
            setFbToken(){
                if(this.authenticated){
                    var formdata = new FormData();
                    formdata.append("device_id", 1);
                    formdata.append("device_name", "web");
                    formdata.append("device_os","web");
                    formdata.append("access_token",this.accessToken);
                    formdata.append("firebase_token",this.fbt);
                    formdata.append("app_version","more v2");
                    if(_.isEmpty(localStorage.getItem("fbtoken"))){
                        // console.log("null fbt");
                        this.SaveUserToken(formdata);
                    }else if(!_.isEmpty(localStorage.getItem("fbtoken")) && localStorage.getItem("fbtoken") != this.fbt){
                        this.SaveUserToken(formdata);
                    }
                }
            },
            filtering(subCategory){
                $(".header-menu-ruangan").trigger("click");
                this.$store.commit("product/list/resetFilter", true);
                this.$store.commit("product/list/setFiltered", {
                    categories: [subCategory.id],
                });
                if(this.$route.name != "Products") this.$router.push('/products');
                window.scrollTo(0, 0);
            },
            toInbox(){
                if(this.authenticated){
                    this.$router.replace("/profile/chats");
                }else{
                    this.$router.replace("/login");
                }
            },
            async getData(page = 1){
                let data={
                    page : 1,
                    per_page:4
                };
                await this.fetchData(data);
                // console.log(this.data.lenght);
            },
            searching() {
                fbq('track', 'Search', {
                    content_category:"Search",
                    currency:"IDR",
                    search_string:this.search,
                    value:this.search
                });
                
    
                this.$analytics.fbq.event('Search', {
                    keyword: this.search
                });
                //this.$gtag.event('Search', { keyword: this.search });
                window.location.href = '/products/q='+this.search;
                // if (this.$route.name != "Products") {
                //     this.$store.commit("product/list/setCategoryGroup", null);
                //     this.$store.commit("product/list/resetFilter");
                //     this.$store.commit("product/list/setFiltered", { q: this.search });
                //     this.$router.push('/products')
                // }else{
                //     this.$store.commit("product/list/resetFilter");
                //     this.$store.commit("product/list/setFiltered", { q: this.search });
                // }
                // window.scrollTo(0, 0);
                // console.log(this.search);
            },
            searchMobile(){
                var val = $("#input-search-mobile").val();
                // alert(val);
                fbq('track', 'Search', {
                    content_category:"Search",
                    currency:"IDR",
                    search_string:val,
                    value:val
                });
                if (this.$route.name != "Products") {
                    this.$store.commit("product/list/setCategoryGroup", null);
                    this.$store.commit("product/list/resetFilter");
                    this.$store.commit("product/list/setFiltered", { q: val });
                    this.$router.push('/products')
                }else{
                    this.$store.commit("product/list/resetFilter");
                    this.$store.commit("product/list/setFiltered", { q: val });
                }
    
                this.$analytics.fbq.event('Search', {
                    keyword: val
                });
                //this.$gtag.event('Search', { keyword: val });
                window.scrollTo(0, 0);
            },
            filterGroupCategories(val){
                this.$store.commit("product/list/resetFilter", true);
                this.$store.commit("product/list/setFiltered", {
                    category_group:val,
                });
                if(this.$route.name != "Products") this.$router.push('/products');
                window.scrollTo(0, 0);
            },
            filterBrand(val){
                this.$store.commit("product/list/resetFilter", true);
                this.$store.commit("product/list/setFiltered", {
                    brands: [val],
                });
                if(this.$route.name !== "Products") this.$router.push('/products');
                window.scrollTo(0, 0);
            },
            allProduct(val){
                this.$store.commit("product/list/resetFilter", true);
                this.$store.commit("product/list/setFiltered", {
                    type:val,
                });
                if(this.$route.name != "Products") this.$router.push('/products');
                window.scrollTo(0, 0);
                // this.toogleMenu();
            },
            bundling(){
                this.$store.commit("product/list/resetFilter", true);
                this.$store.commit("product/list/setFiltered", {
                    category_group: [123],
                });
                if(this.$route.name != "Products") this.$router.push('/products');
                // this.toogleMenu();
            },

            removeVariant(variant){
                this.$store.commit('transaction/cart/remove', variant);
                this.synching();
            },
            toogleMenu(){
                if($("#menu").is(':checked')){
                    $('#menu').trigger('click');
                }
            },
            async getCart(){
                await this.get();
                this.$store.commit('transaction/checkout/setItems', this.variants)
    
            },
            async synching(usingQueue = true){
                if(this.authenticated){
                    if(this.queue != null) clearTimeout(this.queue);
                    if(usingQueue){
                        this.queue = setTimeout(async () => {
                            await this.save();
                        }, 250);
                    }else{
                        await this.save();
                    }
                }
            },
            jq(){
                let jq = {
                    menuFixed: () => {
                    1 < document.body.scrollTop || 1 < document.documentElement.scrollTop
                        ? $("#body").addClass("floating")
                        : $("#body").removeClass("floating");
                    },
                    scrolled: () => {
                    $(window).on("scroll", function() {
                        1 < $(window).scrollTop() &&
                        ($(".ol-header-category__nav > li > a").removeClass("active"),
                        $(".ol-header-category__nav-sub").hide(),
                        $("#ol-header-fix").css("min-height", ""),
                        $(".ol-header-category-content").removeClass("show"));
                        jq.menuFixed();
                    });
                    },
                    init: () => {
                        jq.scrolled();
                    },
                };
                return jq.init();
            },
        },
        async mounted() {
            await this.getCart();
            await this.getCategories();
            await this.getCategoriesGroup();
            this.search = this.filtered.q;
            this.setAppEnv(process.env.appEnv);		
            this.jq();
            this.initFbToken();
            this.getData();
            this.getInbox();
            this.setMenuMobile();
            
            
            $('.m-menu__overlay').on('click',function(event){
                const body = document.body;
                body.style.overflow = 'visible';
            });

            $('.m-menu__toggle_close').on('click',function(event){
                const body = document.body;
                body.style.overflow = 'visible';
            });

            $('.m-menu__toggle').on('click',function(event){
                const body = document.body;
                body.style.overflow = 'hidden';
            });
            
            $(".toggle-parent").on('click', function(event){
                var a = $(this).attr('for');
                var check = $('.'+a).css('display');
                if(check == 'block'){
                    $('.'+a).css("display","none");
                }else{
                    $('.ul-parent').css("display","none");
                    $('.'+a).css("display","block");
                }
            });

            $(".toggle-child").on('click', function(event){
                var a = $(this).attr('for');
                var check = $('.'+a).css('display');
                if(check == 'block'){
                    $('.'+a).css("display","none");
                }else{
                    $('.ul-child').css("display","none");
                    $('.'+a).css("display","block");
                }
                
                
            });

        },
        watch: {
            authenticated: function(value) {
                if (!value) this.$router.replace("/");
            },
            'filtered.q':function(value){
                this.search = value;
            },
            // search: debounce(function () {
            //     this.searchProduct();
            // }, 1000),
        },
    };
    </script>
    
    <style>
    
    .searchListMainDiv ul {
        position:absolute;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 0.5rem;
        max-height: 200px;
        overflow-y: auto;
        margin-top:50px;
        background: white;
        width:40%;
        z-index:99 !important;
        border-radius:4px;
        /* border: 4px solid #E8EAED; */
    }
    .searchListMainDiv ul::-webkit-scrollbar {
      width: 5px;
    }
    .searchListMainDiv ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ddd;
      border-radius: 10px;
    }
    .searchListMainDiv ul::-webkit-scrollbar-thumb {
      background: rgb(183, 183, 183);
      border-radius: 10px;
    }
    .searchListMainDiv ul::-webkit-scrollbar-thumb:hover {
      background: #a2a2a2;
    }
    

    .searchListMainDiv ul li {
      /* padding: 1.2rem 10px; */
      padding: 16px 8px;
      font-size: 15px;
      font-weight: 500;
      line-height: 0;
      /* border-bottom: 1px solid #ddd; */
      color: #333;
      cursor: pointer;
    }
    .searchListMainDiv ul li:last-child {
      border: none;
    }
    </style>
    