import apiurl from '@/util/apiurl'
import * as moment from 'moment'
const PRODUCT = apiurl.WS_PRODUCT;
export default {
    namespaced: true,
    state: {
        products: [],
        topProducts: [],
        categories: [],
        categoriesGroup: [],
        categoryGroup: null,
        filterParameters: {
            categories: [],
            colors: [],
            materials: [],
            brands: [],
        },
        filtered: {
            q: "",
            per_page: 12,
            page: 1,
            type: "",
            category_group: null, //level1
            categories: [],//level 2
            sub_category: null,//level 3
            materials: [],
            colors: [],
            brands: [],
            price_min: 0,
            price_max: 0,
            room_type: null,
            is_bundle: 0,
        },
        copiedFilter: false,
        searchHistory: !JSON.parse(localStorage.getItem("searchHistory")) ? [] : JSON.parse(localStorage.getItem("searchHistory")),
    },
    getters: {
        products: (state) => state.products,
        categories: (state) => state.categories,
        categoriesGroup: (state) => state.categoriesGroup,
        filterParameters: (state) => state.filterParameters,
        filtered: (state) => state.filtered,
        categoryGroup: (state) => state.categoryGroup,
        topProducts: (state) => state.topProducts,
        copiedFilter: (state) => state.copiedFilter,
        searchHistory: (state) => state.searchHistory,
        lastSearchHistory: (state) => {
            if(state.searchHistory.length > 0) return state.searchHistory[state.searchHistory.length - 1];
            return null;
        },
    },
    mutations: {
        setProducts: (state, products) => (state.products = products),
        setTopProducts: (state, topProducts) => (state.topProducts = topProducts),
        setCategories: (state, categories) => (state.categories = categories),
        setCategoriesGroup: (state, categoriesGroup) => (state.categoriesGroup = categoriesGroup),
        setCategoryGroup: (state, categoryGroup) => (state.categoryGroup = categoryGroup),
        setFilterParameters: (state, filterParameters) => (state.filterParameters = filterParameters),
        setFiltered: (state, filteredItems) => {
            _.forEach(filteredItems, function (value, key) {
                state.filtered[key] = value;
            });
        },
        resetFilter: (state, withQ = false) => {
            if (withQ) state.filtered.q = "";
            state.filtered.per_page = 12,
            state.filtered.page = 1;
            state.filtered.type = "";
            state.filtered.category_group = null;
            state.filtered.categories = [];
            state.filtered.sub_category = null;
            state.filtered.materials = [];
            state.filtered.colors = [];
            state.filtered.brands = [];
            state.filtered.price_min = 0;
            state.filtered.price_max = 0;
        },
        setCopiedFilter: (state, copiedFilter) => (state.copiedFilter = copiedFilter),
        setFilteredCategoryGroup: (state, payload) => (state.filtered.category_group = payload),
        setFilteredCategory: (state, payload) => (state.filtered.categories = [payload]),
        setFilteredBrand: (state, payload) => (state.filtered.brands = [payload]),
        setFilteredType: (state, payload) => (state.filtered.type = payload),
        pushSearchHistory: (state, payload) => {
            let searchHistory = [...state.searchHistory, payload];
            state.searchHistory = searchHistory;
        },
        pullSearchHistory: (state) => {
            let searchHistory = state.searchHistory;
            searchHistory.pop();
            state.searchHistory = searchHistory;
        },
        // setSearchHistory: (state) => {
        //     if (state.filtered) {
        //         let filtered = state.filtered;
        //         filtered["created_at"] = moment().format('YYYY-MM-DD HH:mm:ss');

        //         state.searchHistory.push(state.filtered);
        //         localStorage.setItem("searchHistory", JSON.stringify(state.searchHistory));
        //     }
        // },

    },
    actions: {
        async fetchProducts({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if(state.filtered.page == 1 ) commit("setProducts", []);
            await PRODUCT.GET(state.filtered).then(response => {
                result = response.data;
                let products = result.data.results;
                if (state.filtered.page > 1) {
                    products = state.products;
                    products.push(...result.data.results);
                }
                commit("setProducts", products);
            }).catch(error => {
                // console.error("fetchProducts", error);
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async fetchTopProducts({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (state.topProducts.length < 1) {
                await PRODUCT.TOP().then(response => {
                    result = response.data;
                    commit("setTopProducts", result.data);
                }).catch(error => {
                    // console.error("fetchTopProducts", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async getCategories({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (_.isEmpty(state.categories)) {
                await PRODUCT.GET_KATEGORI().then(response => {
                    result = response.data;
                    commit("setCategories", result.data);
                }).catch(error => {
                    // console.error("getCategories", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async getCategoriesGroup({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            if (_.isEmpty(state.categoriesGroup)) {
                await PRODUCT.GET_KATEGORI_GROUP().then(response => {
                    result = response.data;
                    commit("setCategoriesGroup", result.data);
                }).catch(error => {
                    // console.error("setCategoriesGroup", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
        async fetchFilterParameters({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            // if (state.filterParameters.categories.length < 1 || state.filterParameters.materials.length < 1 || state.filterParameters.brands < 1 || state.filterParameters.colors < 1) {
            if (_.isEmpty(state.filterParameters.categories) || _.isEmpty(state.filterParameters.materials) || _.isEmpty(state.filterParameters.brands) || _.isEmpty(state.filterParameters.colors)) {
                await PRODUCT.GET_FILTER_DATA().then(response => {
                    result = response.data;
                    commit("setFilterParameters", result.data);
                }).catch(error => {
                    // console.error("fetchFilterParameters", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },
    },
};
