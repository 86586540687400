import apiurl from '@/util/apiurl';
const PRODUCT = apiurl.WS_PRODUCT;
const CART = apiurl.WS_TRANSACTION.CART;
export default {
    namespaced:true,
    state: {
        product: {},
        selectedVariant: null,
        selectedColor: null,
        related:[],
        relatedInspiration:[],
        recommended:[],
        partner:[],
        voucher:[],
        rating: null,
        reviews: [],
        reviewsInfo: {
            page:1,
            per_page: 3,
            product_id:0,
            total: 0,
            total_pages: 0,
            rating:null,
        },
        location: null,
        productFlash: null,
        productFree:null,
        productPreOrder:null,
        collection: !JSON.parse(sessionStorage.getItem("collection")) ? [] : JSON.parse(sessionStorage.getItem("collection")),
        utm_content: !JSON.parse(localStorage.getItem("utm_content")) ? [] : JSON.parse(localStorage.getItem("utm_content")),
        view_link: localStorage.getItem("view_link") ? null : localStorage.getItem("view_link"),
    },
    getters: {
        product: (state) => state.product,
        selectedVariant: (state) => state.selectedVariant,
        selectedColor: (state) => state.selectedColor,
        getSelectedFinalVariant: (state) => {
            let variant = state.selectedVariant ? state.selectedVariant : state.selectedColor ? state.selectedColor : null;
            // if (variant) {
            //     variant.uni_product_variants_id = variant.id;
            //     variant.flashsale = state.productFlash ? state.productFlash : null;
            //     variant.is_flashsale = variant.flashsale && variant.flashsale.uni_product_variants_id == variant.id ? 1 : 0;
            // }
            return variant;
        },
        
        related: (state) => state.related,
        partner: (state) => state.partner,
        voucher: (state) => state.voucher,
        relatedInspiration: (state) => state.relatedInspiration,
        recommended: (state) => state.recommended,
        rating: (state) => state.rating,
        reviews: (state) => state.reviews,
        location: (state) => state.location,
        reviewsInfo: (state) => state.reviewsInfo,
        productFlash: (state) => state.productFlash,
        collection: (state) => state.collection,
        productFree: (state) => state.productFree,
        productPreOrder: (state) => state.productPreOrder,
        getUtmContent: (state) => state.utm_content,
        getViewLink: (state) => state.view_link,
    },
    mutations: {
        setProduct: (state, product) => {
            state.product = product;
            if (!_.find(state.collection, { id: product?.id })) {
                state.collection.push(product);
                sessionStorage.setItem("collection", JSON.stringify(state.collection));
            }
        },
        setRelated: (state, related) => (state.related = related),
        setPartner: (state, partner) => (state.partner = partner),
        setVoucher: (state, voucher) => (state.voucher = voucher),
        setRecommended: (state, recommended) => (state.recommended = recommended),
        setRelatedInspiration: (state, relatedInspiration) => (state.relatedInspiration = relatedInspiration),
        setSelectedVariant: (state, selectedVariant) => (state.selectedVariant = selectedVariant),
        setSelectedColor: (state, selectedColor) => (state.selectedColor = selectedColor),
        setReviews: (state, reviews) => (state.reviews = reviews),
        setProductFree: (state, productFree) => (state.productFree = productFree),
        setProductPreOrder: (state, productPreOrder) => (state.productPreOrder = productPreOrder),
        setReviewsInfo: (state, reviewsInfo) => {
            _.forEach(reviewsInfo, function (value, key) {
                state.reviewsInfo[key] = value;
            });
        },
        setProductFlash: (state, productFlash) => (state.productFlash = productFlash),
        setUtmContent: (state, utm_content) => (state.utm_content = utm_content),
        setViewLink: (state, view_link) => (state.view_link = view_link),
    },
    actions: {
        async fetchProduct({ commit, state }, slug, ) {
            commit("setSelectedVariant", null);
            commit("setSelectedColor", null);
            let result = { success: true };
            let existed = _.find(state.collection, { slug: slug });
            let founded = null;
            if (existed) founded = _.find(existed.r_uni_product_variants, (item) => (item.r_uni_product_flashsale != null || item.r_uni_product_get_free != null || item.r_uni_product_get_po != null));
            
            if (!existed || founded ){
                await PRODUCT.SHOW(slug).then(response => {
                    // console.log(response.data.data);
                    existed = response.data.data;
                }).catch(error => {
                    // console.error("fetchProduct", error);
                    result = error.response.data;
                });
            }

            if (result.success && (existed.r_uni_product_variants).length > 0) {
                if ((existed.r_uni_product_variants).length == 1) commit("setSelectedVariant", existed.r_uni_product_variants[0]);
                existed.variants_available = _.filter(existed.r_uni_product_variants, (item) => !_.isEmpty(item.variant)).length;
                existed.colors_available = _.filter(existed.r_uni_product_variants, (item) => !_.isEmpty(item.color)).length;
            }   
            commit("setProduct", existed);
            
            return result;
        },
        
        async fetchRelated({ commit, state }, categories, onFinally = () => { }) {
            let result = { success: false };
            if (_.isEmpty(state.related)) {
                await PRODUCT.RELATED(categories).then(response => {
                    result = response.data;
                    commit("setRelated", result.data.results);
                }).catch(error => {
                    // console.error("fetchRelated", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },

        async fetchPartner({ commit, state }, product_id, onFinally = () => { }) {
            let result = { success: false };
            if (_.isEmpty(state.partner)) {
                await PRODUCT.PARTNER(product_id).then(response => {
                    result = response.data;
                    commit("setPartner", result.data);
                }).catch(error => {
                    // console.error("fetchPartner", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },

        async fetchVoucher({ commit, state }, product_id, onFinally = () => { }) {
            let result = { success: false };
            // if (_.isEmpty(state.voucher)) {
                // PRODUCT.VOUCHER(product_id)
                
                await CART.GET_VOUCHER().then(response => {
                    result = response.data;
                    commit("setVoucher", result.data);
                }).catch(error => {
                    // console.error("fetchVoucher", error);
                    result = error.response.data;
                });
            // }
            onFinally();
            return result;
        },

        async fetchRelatedInspiration({ commit, state }, product_id, onFinally = () => { }) {
            let result = { success: false };
            if (_.isEmpty(state.relatedInspiration)) {
                await PRODUCT.RELATED_INSPIRATION(product_id).then(response => {
                    result = response.data;
                    commit("setRelatedInspiration", result.data);
                }).catch(error => {
                    // console.error("fetchRelatedInspiration", error);
                    result = error.response.data;
                });
            }
            onFinally();
            return result;
        },

        async fetchReviews({ commit, state }, payload, onFinally = () => { }) {
            commit("setReviewsInfo",payload);
            let result = { success: false };
            let data = state.reviewsInfo;
            await PRODUCT.REVIEWS(data).then(response => {
                result = response.data;
                commit("setReviews", result.data.results);
                commit("setReviewsInfo", {
                    total: result.data.total,
                    total_pages: Math.ceil(result.data.total/parseInt(data.per_page)),
                });
            }).catch(error => {
                // console.error("fetchReviews", error);
                result = error.response.data;
            });
            onFinally();
            return result;
        },

        async fetchProductFlash({ commit, state, getters }) {
            commit("setProductFlash", null);
            if (getters.getSelectedFinalVariant) {
                getters.getSelectedFinalVariant.r_uni_product_flashsale;
                commit("setProductFlash", getters.getSelectedFinalVariant.r_uni_product_flashsale);
            }
        },

        async fetchProductFree({ commit, state, getters }) {
            commit("setProductFree", null);
            if (getters.getSelectedFinalVariant) {
                commit("setProductFree", getters.getSelectedFinalVariant.r_uni_product_get_free);
            }
        },

        async fetchProductPreOrder({ commit, state, getters }) {
            commit("setProductPreOrder", null);
            if (getters.getSelectedFinalVariant) {
                commit("setProductPreOrder", getters.getSelectedFinalVariant.r_uni_product_get_po);
            }
        },

        async addUtmContent({ commit, state }, payload) {
            let utmContentExists = _.find(state.utm_content, function(o) {
                return o === payload
            });
            if(!utmContentExists) {
                let newUtmContent = [...state.utm_content, payload];
                commit("setUtmContent", newUtmContent);
                localStorage.setItem("utm_content", JSON.stringify(newUtmContent));
            }
        },

        async removeUtmContent({ commit }) {
            commit("setUtmContent", []);
            localStorage.setItem("utm_content", JSON.stringify([]));
        },

        async addViewLink({ commit, state }, payload) {
            commit("setViewLink", 'retail');
            localStorage.setItem("view_link", 'retail');
        
        },
    },
};
